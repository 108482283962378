@import "../colors.css";

.header {
  min-height: 220px;
}

.logo {
  width: 150px;
  margin: 50px 0 0 -16px;
}

.dropbox-icon {
  color: var(--dropbox);
}

.dropbox-icon:hover {
  color: var(--vanilla);
}
