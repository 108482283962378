@import "./theme/colors.css";
@import "./theme/constants.css";

body {
  margin: 0;
  background-color: var(--night);
  font-family: var(--body-font);
}

@media only screen and (max-width: 600px) {
  body {
    padding: 0 25px;
  }
}
